import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Image from "next/legacy/image";
import NextLink from "next/link";
import { Trans, useTranslation } from "next-i18next";
import React, { ReactElement } from "react";

import ConfidentialIcon from "../../assets/confidential.svg";
import ConnectionPathIcon from "../../assets/connection-path.svg";
import DiverseNeedsIcon from "../../assets/diverse-needs.svg";
import GlobeIcon from "../../assets/globe.svg";
import QuickIcon from "../../assets/quick.svg";
import SupportiveIcon from "../../assets/supportive.svg";
import CountryAccordian from "../CountryAccordian";
import Highlight from "../Highlight";
import LinkText from "../LinkText";
import TopicLinks from "../TopicLinks";

interface Props {
  isPage?: boolean;
}

const About = ({ isPage }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          pt: !isPage ? 2 : 5,
          px: 0,
          pb: 5,
          backgroundColor: "background.paper",
        }}
      >
        <Container maxWidth="xs">
          {!isPage && (
            <Box mb={5}>
              <Typography
                color="textSecondary"
                sx={{
                  textAlign: "center",
                  mb: 2,
                }}
              >
                {t("Key Partners")}
              </Typography>
              <Divider />
              <Box mx={2} my={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <Image
                      layout="responsive"
                      src="/partners/iasp-logo-grey.png"
                      style={{ width: "100%" }}
                      width={1373}
                      height={238}
                      alt={t(
                        "International Association for Suicide Prevention logo",
                      )}
                      priority
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Image
                      layout="responsive"
                      src="/partners/LLI-logo-grey.svg"
                      style={{ width: "100%" }}
                      width={815}
                      height={279}
                      alt={t("LifeLine International logo")}
                      priority
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography
                color="textSecondary"
                sx={{
                  textAlign: "center",
                  mb: 2,
                }}
              >
                {t("+ 1,300 helplines in over 130 countries")}
              </Typography>
              <Divider />
            </Box>
          )}
          <Typography variant="h5" align="center" component="h2">
            {t("Free emotional support, anywhere, anytime")}
          </Typography>
          <Box
            sx={{
              p: 2,
              textAlign: "center",
              "& img": {
                width: "90%",
              },
            }}
          >
            <Image
              layout="responsive"
              src="/illustrations/man-in-hammock-texting-hotline.png"
              width={1147}
              height={570}
              alt={t(
                "Illustration of a man reclining in a hammock text messaging a suicide hotline, symbolizing online emotional support",
              )}
              priority
            />
          </Box>
          <Highlight
            title={t("Quick")}
            description={t("Connect with a helpline for support today")}
            Icon={QuickIcon}
          />
          <Highlight
            title={t("Talk with a human")}
            description={t(
              "Get support from a trained volunteer, counselor or peer",
            )}
            Icon={SupportiveIcon}
          />
          <Highlight
            title={t("Confidential")}
            description={t("Feel comfortable to share what’s on your mind")}
            Icon={ConfidentialIcon}
          />
          <Box my={4}>
            <Typography variant="h5" gutterBottom component="h2">
              {t("You don’t have to go through this alone.")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "1.125rem",
              }}
              gutterBottom
            >
              {t(
                "If you are struggling today, need support with your mental health, or have experienced a traumatic event, consider contacting a helpline. It’s free, anonymous and confidential.",
              )}
            </Typography>
            <Typography variant="h6" gutterBottom component="h3">
              {t("What are helplines?")}
            </Typography>
            <Trans t={t}>
              <Typography gutterBottom>
                Helplines (also known as hotlines or crisis lines) provide
                immediate crisis counseling, emotional support and information –
                for free.
              </Typography>
              <Typography gutterBottom>
                Most helpline phone numbers are toll-free and many helplines are
                available over text message or online chat. Helplines are often
                available 24/7, so you can contact them at any time of the day
                or night.
              </Typography>
            </Trans>
            <Typography variant="h6" gutterBottom component="h3">
              {t("How will a helpline support me?")}
            </Typography>
            <Trans t={t}>
              <Typography gutterBottom>
                Helplines provide a judgment-free space for you to talk through
                difficult emotions and experiences, receive support, and connect
                to other resources that can help you.
              </Typography>
              <Typography gutterBottom>
                Talking helps, and research has shown that people feel less
                distressed after contacting helplines.
              </Typography>
            </Trans>
            <Typography variant="h6" gutterBottom component="h3">
              {t("What can I talk to a helpline about?")}
            </Typography>
            <Trans
              t={t}
              i18nKey="<0>The most common types of helpline are <2>suicide prevention hotlines</2>, <5>domestic violence hotlines</5> and <9>sexual assault hotlines</9>.</0><1>However, helplines are available for a wide range of mental health and emotional struggles including <2>anxiety</2>, <5>depression</5>, <8>gender or sexual identity</8>, <11>substance use</11> and much more.</1><2>You don't have to be suicidal or in a severe situation to contact a helpline. You might just need to talk, or want information on <4>supporting others</4>.</2>"
            >
              <Typography gutterBottom>
                The most common types of helpline are{" "}
                <LinkText underline href="/topics/suicidal-thoughts">
                  suicide prevention hotlines
                </LinkText>
                ,{" "}
                <LinkText underline href="/topics/abuse-domestic-violence">
                  domestic violence hotlines
                </LinkText>{" "}
                and{" "}
                <LinkText underline href="/topics/sexual-abuse">
                  sexual assault hotlines
                </LinkText>
                .
              </Typography>
              <Typography gutterBottom>
                However, helplines are available for a wide range of mental
                health and emotional struggles including{" "}
                <LinkText underline href="/topics/anxiety">
                  anxiety
                </LinkText>
                ,{" "}
                <LinkText underline href="/topics/depression">
                  depression
                </LinkText>
                ,{" "}
                <LinkText underline href="/topics/gender-sexual-identity">
                  gender or sexual identity
                </LinkText>
                ,{" "}
                <LinkText underline href="/topics/substance-use">
                  substance use
                </LinkText>{" "}
                and much more.
              </Typography>
              <Typography gutterBottom>
                You don&apos;t have to be suicidal or in a severe situation to
                contact a helpline. You might just need to talk, or want
                information on{" "}
                <LinkText underline href="/topics/supporting-others">
                  supporting others
                </LinkText>
                .
              </Typography>
            </Trans>
            <Typography variant="h6" gutterBottom component="h3">
              {t("How do I find the best helpline for me?")}
            </Typography>
            <Trans t={t}>
              <Typography gutterBottom>
                In some countries, many helplines are available. For example, if
                you live in the United States, popular nationwide helplines are
                the{" "}
                <LinkText
                  underline
                  href="/organizations/988-suicide-crisis-lifeline"
                >
                  988 Suicide & Crisis Lifeline
                </LinkText>
                ,{" "}
                <LinkText underline href="/organizations/crisis-text-line">
                  Crisis Text Line
                </LinkText>{" "}
                and{" "}
                <LinkText underline href="/organizations/trevorlifeline">
                  TrevorLifeline
                </LinkText>
                .
              </Typography>
              <Typography gutterBottom>
                When you search for a helpline on Find A Helpline, our
                intelligent ranking algorithm will recommend the best helplines
                for you.
              </Typography>
              <Typography gutterBottom>
                You can also further filter according to your needs, and read
                helpline descriptions to learn more about the service each
                helpline provides.
              </Typography>
              <Typography gutterBottom>
                Because helplines are free, you can contact more than one
                service to find the right one for you.
              </Typography>
            </Trans>
          </Box>
          <Box my={4}>
            <NextLink
              legacyBehavior
              href="/faq"
              passHref
              prefetch={process.env.NODE_ENV === "production"}
            >
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                endIcon={<ArrowRightAltRoundedIcon />}
              >
                {t("What to expect")}
              </Button>
            </NextLink>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          py: 5,
          px: 0,
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              p: 2,
              textAlign: "center",
              "& img": {
                width: "90%",
              },
            }}
          >
            <Image
              layout="responsive"
              src="/illustrations/woman-on-phone-chatting-to-crisis-counselor-transparent.png"
              width={1271}
              height={862}
              alt={t(
                "Illustration of a woman messaging a crisis counselor, with chat bubbles and shapes extending from her phone",
              )}
            />
          </Box>
          <Box my={4}>
            <Typography variant="h5" gutterBottom component="h2">
              {t("We partner with verified helplines in 130+ countries")}
            </Typography>
            <Trans t={t}>
              <Typography gutterBottom>
                Find A Helpline is powered by{" "}
                <Link
                  sx={{
                    textDecoration: "underline",
                    color: "text.primary",
                  }}
                  href="https://www.throughlinecare.com"
                  target="_blank"
                  rel="noopener"
                >
                  ThroughLine
                </Link>
                , the largest, most accurate helpline resource in the world.
              </Typography>
              <Typography gutterBottom>
                We hold relationships with helpline organisations and crisis
                centers in over 130 countries, who verify their information with
                us directly.
              </Typography>
              <Typography>
                Other services and websites provide long lists of out-of-date
                information, but we work daily to keep our information accurate
                and easy to use – both for the people who need immediate support
                and for the partners who rely on us.
              </Typography>
            </Trans>
          </Box>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="none"
                    colSpan={2}
                    sx={{
                      border: 0,
                    }}
                  >
                    <Typography variant="h6" color="error">
                      {t("Other services")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    padding="none"
                    colSpan={2}
                    sx={{
                      border: 0,
                    }}
                  >
                    <Typography variant="h6" color="secondary">
                      {t("Find A Helpline")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell padding="none" align="center">
                    <CloseRoundedIcon color="error" />
                  </TableCell>
                  <TableCell>
                    <Typography>{t("Some countries")}</Typography>
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <CheckRoundedIcon color="secondary" />
                  </TableCell>
                  <TableCell>
                    <Typography>{t("Global coverage")}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="none" align="center">
                    <CloseRoundedIcon color="error" />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {t("Outdated websites & phone numbers")}
                    </Typography>
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <CheckRoundedIcon color="secondary" />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {t("Verified data, maintained daily")}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding="none" align="center">
                    <CloseRoundedIcon color="error" />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {t("Difficult to find the right service")}
                    </Typography>
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <CheckRoundedIcon color="secondary" />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {t("AI-powered intelligent ranking")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box my={4}>
            <Typography variant="h6" gutterBottom component="h3">
              {t("Helplines & hotlines by country")}
            </Typography>
            <CountryAccordian />
          </Box>
          <Box my={4}>
            <Typography variant="h6" gutterBottom component="h3">
              {t("Helplines & hotlines by topic")}
            </Typography>
            <TopicLinks />
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          py: 5,
          px: 0,
          backgroundColor: "background.paper",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              p: 2,
              textAlign: "center",
              "& img": {
                width: "90%",
              },
            }}
          >
            <Image
              layout="responsive"
              src="/illustrations/man-in-screen-talking-with-stranger.png"
              width={936}
              height={923}
              alt={t(
                "Illustration of a woman meeting a caring person, who is in a life-sized phone, to symbolize online support and connection",
              )}
            />
          </Box>
          <Typography variant="h6" gutterBottom component="h2">
            {t("Common reasons for contacting a helpline")}
          </Typography>
          <Trans t={t}>
            <Typography gutterBottom>
              Unsure if contacting a helpline is right for you? Here are some
              reasons other people have sought free, confidential support from a
              helpline or hotline:
            </Typography>
            <Typography component="ul">
              <Typography gutterBottom component="li">
                Experiencing a suicidal crisis, such as thinking “I want to die”
                or about how to kill yourself, or wanting support after
                attempting to take your life.
              </Typography>
              <Typography gutterBottom component="li">
                Experiencing domestic abuse or violence, sexual abuse,
                depression, anxiety, or other emotional distress.
              </Typography>
              <Typography gutterBottom component="li">
                Struggling with self-harm, an addiction or substance use
                challenge, your body image or an eating disorder.
              </Typography>
              <Typography gutterBottom component="li">
                Noticing warning signs in a loved one, feeling concerned someone
                may be at risk of hurting themselves, or wanting to know how to
                help someone you’re worried about.
              </Typography>
              <Typography gutterBottom component="li">
                Wanting to learn about mental health crisis services, suicide
                prevention, general mental health services and resources.
              </Typography>
              <Typography gutterBottom component="li">
                Just simply thinking “I need help” and not knowing where to
                start.
              </Typography>
            </Typography>
          </Trans>
        </Container>
      </Box>
      <Box
        sx={{
          py: 5,
          px: 0,
          backgroundColor: "primary.main",
          color: "primary.contrastText",
        }}
      >
        <Container
          maxWidth="xs"
          sx={{
            display: "flex",
            gap: 3,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box display="flex" justifyContent="center" width="100%">
            <Typography
              sx={{
                fontWeight: 700,
                textAlign: "center",
                borderRadius: "10px",
                border: "2px solid",
                px: 1.5,
                py: 1,
                display: "inline-block",
              }}
            >
              {t("For Businesses & Organizations")}
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="h5" component="h2">
              {t(
                "Scaling & maintaining global support resources is challenging & costly. We make it easy.",
              )}
            </Typography>
          </Box>
          <Box textAlign="center">
            <Trans>
              <Typography gutterBottom>
                Find A Helpline is a public service by{" "}
                <Link
                  sx={{
                    textDecoration: "underline",
                    color: "primary.contrastText",
                  }}
                  href="https://www.throughlinecare.com"
                  target="_blank"
                  rel="noopener"
                >
                  ThroughLine
                </Link>
                . It&apos;s part of our mission to ease every emotional crisis,
                globally.
              </Typography>
              <Typography>
                ThroughLine empowers online communities and platforms to protect
                their users and brand reputation through verified crisis support
                resources.
              </Typography>
            </Trans>
          </Box>
          <Box textAlign="center" width="100%">
            <Typography
              gutterBottom
              sx={{
                fontSize: "0.875rem",
              }}
            >
              {t("Trusted by")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                alignContent: "flex-end",
                justifyContent: "center",
                px: 3,
                py: 0,
                mb: 1,
              }}
            >
              <Box
                sx={{
                  pr: 2,
                  pb: 1.5,
                }}
              >
                <Image
                  layout="fixed"
                  src="/partners/google-logo-white.svg"
                  width={74}
                  height={24}
                  alt={t("Google logo")}
                  priority
                />
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pb: 1.5,
                }}
              >
                <Image
                  layout="fixed"
                  src="/partners/grammarly-logo-white.svg"
                  width={124}
                  height={29}
                  alt={t("Grammarly logo")}
                  priority
                />
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pb: 1.5,
                }}
              >
                <Image
                  layout="fixed"
                  src="/partners/padlet-logo-white.svg"
                  width={83}
                  height={20}
                  alt={t("Padlet logo")}
                  priority
                />
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pb: 1.5,
                }}
              >
                <Image
                  layout="fixed"
                  src="/partners/konami-logo-white.svg"
                  width={95}
                  height={16}
                  alt={t("Konami logo")}
                  priority
                />
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pb: 1.5,
                }}
              >
                <Image
                  layout="fixed"
                  src="/partners/inflection-logo-white.svg"
                  width={104}
                  height={20}
                  alt={t("Inflection logo")}
                  priority
                />
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pb: 1.5,
                }}
              >
                <Image
                  layout="fixed"
                  src="/partners/oliva-logo-white.svg"
                  width={76}
                  height={22}
                  alt={t("Oliva logo")}
                  priority
                />
              </Box>
              <Box
                sx={{
                  pr: 2,
                  pb: 1.5,
                }}
              >
                <Image
                  layout="fixed"
                  src="/partners/btwf-white.svg"
                  width={125}
                  height={24}
                  alt={t("Born This Way Foundation logo")}
                  priority
                />
              </Box>
              <Typography>{t("+ more")}</Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "0.875rem",
              }}
            >
              <Link
                href="https://www.throughlinecare.com"
                target="_blank"
                rel="noopener"
                sx={{
                  textDecoration: "underline",
                  color: "primary.contrastText",
                }}
              >
                {t("Become a partner →")}
              </Link>
            </Typography>
          </Box>
          <Box>
            <Highlight
              variant="paper"
              title={t("Reach every user")}
              description={t("Verified helplines in 130+ countries.")}
              Icon={GlobeIcon}
            />
            <Highlight
              variant="paper"
              title={t("Support diverse needs")}
              description={t("Coverage for 21 topics and 15 specialties.")}
              Icon={DiverseNeedsIcon}
            />
            <Highlight
              variant="paper"
              title={t("Streamline access to help")}
              description={t("Launch-ready, customizable product experiences.")}
              Icon={ConnectionPathIcon}
            />
          </Box>
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            size="large"
            endIcon={<ArrowRightAltRoundedIcon />}
            href="https://www.throughlinecare.com"
            target="_blank"
            rel="noopener"
            sx={{
              color: "text.primary",
            }}
          >
            {t("Learn more")}
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default About;
